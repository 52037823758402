export default function About() {
  return (
    <>
      <div className="px-3 px-md-4 px-xxl-5">
        <div className="bg-image parallax" data-bg-src="../assets/images/startup-bg.jpg">
          <div className="section-spacing-xl bg-gradient-5 bg-opacity-60">
            <div className="container text-center">
              <div className="row g-4">
                <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                  <h1 className="display-3 fw-semi-bold uppercase text-center mb-0">Về chúng tôi</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-spacing">
        <div className="container">
          <div className="row g-4 g-lg-5">
            <div className="col-12 col-lg-6">
              <h1 className="display-6 fw-normal mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing</h1>
            </div>
            <div className="col-12 col-lg-6">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
              </p>
            </div>
          </div>
          <div className="row g-4 mt-3">
            <div className="col-12 col-md-6 col-xl-3">
              <div className="bg-gradient-1 bg-opacity-10 border-radius-1 p-4 backdrop-filter-blur">
                <div className="h2 display-4 fw-light text-dark">
                  <span className="counter">46</span>
                </div>
                <div className="text-end">
                  <div className="sm-heading text-dark">Team of Creatives</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="bg-gradient-2 bg-opacity-10 border-radius-1 p-4 backdrop-filter-blur">
                <div className="h2 display-4 fw-light text-dark">
                  <span className="counter">400</span>+
                </div>
                <div className="text-end">
                  <div className="sm-heading text-dark">Satisfied Clients</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="bg-gradient-1 bg-opacity-10 border-radius-1 p-4 backdrop-filter-blur">
                <div className="h2 display-4 fw-light text-dark">
                  <span className="counter">223</span>
                </div>
                <div className="text-end">
                  <div className="sm-heading text-dark">Projects Done</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="bg-gradient-5 bg-opacity-10 border-radius-1 p-4 backdrop-filter-blur">
                <div className="h2 display-4 fw-light text-dark">
                  <span className="counter">100</span>%
                </div>
                <div className="text-end">
                  <div className="sm-heading text-dark">Satisfaction</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-spacing pt-0">
        <div className="container">
          <div className="row g-4">
            <div className="col-12 col-md-6">
              <div className="lightbox-video border-radius-1">
                <img src={require("../../../assets/images/startup-about-1.jpg")} alt="" />
                <a className="glightbox lightbox-play-btn" href="https://www.youtube.com/watch?v=V8yu12uRpBA">
                  <i className="bi bi-play-fill"></i>
                </a>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <img className="border-radius-1 mt-md-4" src={require("../../../assets/images/startup-about-2.jpg")} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-12 col-lg-4">
            <div className="circle-text">
              <svg viewBox="0 0 200 200" className="circle-svg rotating">
                <path id="textPath" d="M 85,0 A 85,85 0 0 1 -85,0 A 85,85 0 0 1 85,0" transform="translate(100,100)" fill="none" stroke="rgba(28, 30, 32, 0.1)" strokeWidth="10"></path>
                <text textAnchor="start">
                  <textPath xlinkHref="#textPath" textLength="260%">
                    Hệ Thống Quản Lý Dữ Liệu Khoa Học - Công Nghệ Quốc Gia •
                  </textPath>
                </text>
              </svg>
              <div className="circle-text-inner icon-4xl text-gradient-5">
                <i className="ti-crown"></i>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="d-flex align-items-center">
              <div className="icon-3xl text-gradient-5 pe-4">
                <i className="ti-check-box"></i>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
            </div>
            <div className="d-flex align-items-center mt-3">
              <div className="icon-3xl text-gradient-5 pe-4">
                <i className="ti-check-box"></i>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>
            </div>
            <div className="row g-4 mt-2">
              <div className="col-12 col-md-6">
                <div className="progress-box progress-gradient-5">
                  <h5>App Development</h5>
                  <div className="animated-progress">
                    <div data-progress="98"></div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="progress-box progress-gradient-5">
                  <h5>Graphic Design</h5>
                  <div className="animated-progress">
                    <div data-progress="92"></div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="progress-box progress-gradient-5">
                  <h5>Business Analysis</h5>
                  <div className="animated-progress">
                    <div data-progress="93"></div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="progress-box progress-gradient-5">
                  <h5>AI Development</h5>
                  <div className="animated-progress">
                    <div data-progress="97"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-spacing-sm pb-0">
        <div className="container">
          <div className="swiper clients-slider-6">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="client-box">
                  <a href="#">
                    <img src="../assets/images/client-1-dark.png" alt="" />
                  </a>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="client-box">
                  <a href="#">
                    <img src="../assets/images/client-2-dark.png" alt="" />
                  </a>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="client-box">
                  <a href="#">
                    <img src="../assets/images/client-3-dark.png" alt="" />
                  </a>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="client-box">
                  <a href="#">
                    <img src="../assets/images/client-4-dark.png" alt="" />
                  </a>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="client-box">
                  <a href="#">
                    <img src="../assets/images/client-5-dark.png" alt="" />
                  </a>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="client-box">
                  <a href="#">
                    <img src="../assets/images/client-6-dark.png" alt="" />
                  </a>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="client-box">
                  <a href="#">
                    <img src="../assets/images/client-1-dark.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export class SaveInventionRequest {
  private ownerNm?: string;
  private ownerAddr?: string;
  private ownerPhone?: string;
  private ownerEmail?: string;
  private ownerIdentify?: string;
  private applicantRole?: string;
  private inventionNm?: string;
  private inventionField?: string;
  private inventionSummary?: string;
  private inventionRecognized?: boolean;
  private inventionResult?: string;
  private inventionValue?: string;
  private transferContent?: string;
  private createdBy?: string;
  private relatedDocuments?: any;
  private technicalDocuments?: any;

  public convertRequest(request: any) {
    return {
      ownerNm: request.ownerNm ? request.ownerNm : "",
      ownerAddr: request.ownerAddr ? request.ownerAddr : "",
      ownerPhone: request.ownerPhone ? request.ownerPhone : "",
      ownerEmail: request.ownerEmail ? request.ownerEmail : "",
      ownerIdentify: request.ownerIdentify ? request.ownerIdentify : "",
      applicantRole: request.applicantRole ? request.applicantRole : "",
      inventionNm: request.inventionNm ? request.inventionNm : "",
      inventionField: request.inventionField ? request.inventionField : "",
      inventionSummary: request.inventionSummary ? request.inventionSummary : "",
      inventionRecognized: request.inventionRecognized ? request.inventionRecognized : false,
      inventionResult: request.inventionResult ? request.inventionResult : "",
      inventionValue: request.inventionValue ? request.inventionValue : "",
      transferContent: request.transferContent ? request.transferContent : "",
      createdBy: request.createdBy ? request.createdBy : "",
      relatedDocuments: request.relatedDocuments ? request.relatedDocuments : [],
      technicalDocuments: request.technicalDocuments ? request.technicalDocuments : [],
    };
  }
}

import { useEffect, useState } from "react";

type PaginationProps = {
  totalRecords: any;
  pageSize: any;
  onChange?: (event: any) => void;
};

export default function AppPagination(props: PaginationProps) {
  const [pages, setPages] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<any>(1);

  useEffect(() => {
    let page = Math.ceil(props.totalRecords / props.pageSize);
    const arr = Array.from({ length: page }, (v, i) => i + 1);
    setPages(arr);
  }, [props.pageSize, props.totalRecords]);

  const onNext = () => {
    if (currentPage < pages.length) {
      setCurrentPage((prev: any) => prev + 1);
      props.onChange?.(currentPage + 1);
    }
  };

  const onPrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prev: any) => prev - 1);
      props.onChange?.(currentPage - 1);
    }
  };

  const onChangePage = (item: any) => {
    setCurrentPage(item);
    props.onChange?.(item);
  };

  return (
    <>
      <nav>
        <ul className="pagination flex">
          {currentPage > 1 && (
            <li className="page-item">
              <a className="page-link rounded-md flex justify-center items-center" onClick={onPrev}>
                <i className="bi bi-arrow-left text-dark"></i>
              </a>
            </li>
          )}

          {pages.map((item: any, idx: any) => (
            <li key={idx} className="page-item">
              <a onClick={() => onChangePage(item)} className={"page-link rounded-md flex justify-center items-center text-dark" + (currentPage == item ? " active" : "")}>
                {item}
              </a>
            </li>
          ))}

          {currentPage < pages.length && (
            <li className="page-item">
              <a className="page-link rounded-md flex justify-center items-center" onClick={onNext}>
                <i className="bi bi-arrow-right text-dark"></i>
              </a>
            </li>
          )}
        </ul>
      </nav>
    </>
  );
}

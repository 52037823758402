export default function Register() {
  return (
    <>
      <div className="px-3 px-md-4 px-xxl-5">
        <div className="bg-image parallax" data-bg-src="../assets/images/startup-bg.jpg">
          <div className="section-spacing-xl bg-gradient-5 bg-opacity-60">
            <div className="container text-center">
              <div className="row g-4">
                <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                  <h1 className="display-3 fw-semi-bold uppercase text-center mb-0">Đăng ký</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-spacing">
        <div className="container">
          <div className="row g-5 justify-content-center">
            <div className="col-6">
              <form className="form-border-radius">
                <input type="text" id="name" name="name" placeholder="Họ và tên" required />
                <input type="phone" id="phone" name="phone" placeholder="Số điện thoại" required />
                <input type="email" id="email" name="email" placeholder="E-Mail" required />
                <input type="password" id="password" name="password" placeholder="Mật khẩu" required />
                <input type="password" id="cfpassword" name="cfpassword" placeholder="Xác nhận mật khẩu" required />
                <div className="d-flex justify-content-center">
                  <button className="button button-lg button-rounded button-gradient-5 button-hover-slide" type="submit">
                    <span data-text="Đăng Ký">Đăng Ký</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default function Login() {
  return (
    <>
      <div className="px-3 px-md-4 px-xxl-5">
        <div className="bg-image parallax" data-bg-src="../assets/images/startup-bg.jpg">
          <div className="section-spacing-xl bg-gradient-5 bg-opacity-60">
            <div className="container text-center">
              <div className="row g-4">
                <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                  <h1 className="display-3 fw-semi-bold uppercase text-center mb-0">Đăng nhập</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-spacing">
        <div className="container">
          <div className="row g-5 justify-content-center">
            <div className="col-6">
              <form className="form-border-radius">
                <input type="email" id="email" name="email" placeholder="E-Mail" required />
                <input type="password" id="password" name="password" placeholder="Mật khẩu" required />
                <div className="mb-3">
                  <span>Bạn chưa có tài khoản ?</span>&nbsp;
                  <span>
                    <a href="/dang-ky">
                      <b>Đăng ký</b>
                    </a>
                  </span>
                </div>
                <div className="d-flex justify-content-center">
                  <button className="button button-lg button-rounded button-gradient-5 button-hover-slide" type="submit">
                    <span data-text="Đăng Nhập">Đăng Nhập</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

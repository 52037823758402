import React from "react";
import "./App.css";
import { Navigate, useRoutes } from "react-router-dom";
import { indexRouter } from "./app/routers/indexRouter";

function App() {
  let router = useRoutes([{ path: "/", element: <Navigate to="/trang-chu" replace /> }, indexRouter]);

  return <>{router}</>;
}

export default App;

import moment from "moment";
import AppModal from "../../components/common/AppModal";
import AppPagination from "../../components/common/AppPagination";
import ViewApproveStatus from "../../components/ViewApproveStatus";
import ViewInventionStatus from "../../components/ViewInventionStatus";
import { CommonConstant } from "../../core/constant/common.constant";
import InventionService from "../../core/services/InventionService";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../libs/hooks";
import { useSearchParams } from "react-router-dom";

export default function Search() {
  const [listInvention, setListInvention] = useState<any>([]);
  const [isShowModal, setIsShowModal] = useState<any>(false);
  const [inventionSlt, setInventionSlt] = useState<any>({});
  const [totalItems, setTotalItems] = useState<any>();
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const [searchReq, setSearchReq] = useState<any>({});
  const [inventionNm, setInventionNm] = useState<any>("");

  const [searchParams] = useSearchParams();

  useEffect(() => {
    let inventionNm = searchParams.get("ten-sang-kien");
    setInventionNm(inventionNm);
    setSearchReq({ page: 0, rows: 10, approveStatus: CommonConstant.INVENTION_APPROVE_STATUS_APPROVED, inventionNm: inventionNm ? inventionNm : "" });
  }, [userInfo]);

  useEffect(() => {
    if (Object.keys(searchReq).length > 0) getListInvention();
  }, [searchReq]);

  const getListInvention = () => {
    InventionService.getInstance()
      .searchInventionApproved(searchReq)
      .then((res: any) => {
        if (res.data.status) {
          setListInvention(res.data.responseData.result);
          setTotalItems(res.data.responseData.totalItem);
        }
      });
  };

  const handleSelectRow = (invention: any) => {
    setInventionSlt(invention);
    setIsShowModal(true);
  };

  const onChangePagi = (event: any) => {
    setSearchReq({ ...searchReq, page: event - 1 });
  };

  const onSearch = () => {
    setSearchReq({ ...searchReq, inventionNm: inventionNm });
  };

  return (
    <>
      <div className="container pt-3">
        <div className="row g-4 flex-nowrap gap-4">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="col-lg-3">
                <h3 className="text-dark">Danh sách sáng chế</h3>
              </div>
              <div className="col-lg-6 d-flex align-items-center">
                <div className="col-lg-9">
                  <input type="text" value={inventionNm} onChange={(e) => setInventionNm(e.target.value)} className="mb-0" placeholder="Tên sáng chế"></input>
                </div>
                <div className="col-lg-3">
                  <div className="button button-sm button-gradient-5 cursor-pointer button-hover-slide ms-3 float-end" onClick={onSearch}>
                    Tìm kiếm
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gradient-5 bg-opacity-10 p-4  backdrop-filter-blur border-radius-1">
              <table className="w-100 text-dark">
                <thead className="border-bottom border-secondary">
                  <th>Tên sáng chế</th>
                  <th>Lĩnh vực nghiên cứu</th>
                  <th>Tiến độ</th>
                  <th>Ngày đăng ký</th>
                  <th>Trạng thái</th>
                </thead>
                <tbody>
                  {listInvention.map((invention: any, idx: any) => (
                    <tr key={idx} onClick={() => handleSelectRow(invention)} className="border-bottom border-secondary cursor-pointer">
                      <td>{invention.inventionNm}</td>
                      <td>{invention.applicantRole.commNm}</td>
                      <td>
                        <ViewInventionStatus value={invention.status} />
                      </td>
                      <td>{moment(invention.createdDt).format("DD/MM/YYYY")}</td>
                      <td>
                        <ViewApproveStatus value={invention.approveStatus} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-center mt-3">
                <AppPagination pageSize={10} totalRecords={totalItems} onChange={onChangePagi} />
              </div>
            </div>
          </div>
        </div>
        <AppModal isShow={isShowModal} title={"Chi tiết sáng chế"} showFooter="false" onClose={() => setIsShowModal(false)}>
          <div className="row g-4">
            <div className="col-12 mb-3">
              <div className="bg-gradient-5 bg-opacity-40 p-3 backdrop-filter-blur border-radius-1">
                <h4 className="text-center text-dark mb-3 fw-semibold">{inventionSlt.inventionNm}</h4>
                <div className="invention-progress mb-3">
                  <div className="d-flex justify-content-center steps">
                    <ul className="m-0 p-0">
                      <li className={"step d-flex align-items-center done"}>
                        <div>
                          <span>Đăng ký</span>
                        </div>
                      </li>
                      <li
                        className={
                          "step d-flex align-items-center" +
                          (inventionSlt.status === CommonConstant.INVENTION_STATUS_REGISTERED ? " active" : "") +
                          (inventionSlt.status >= CommonConstant.INVENTION_STATUS_VALUATION ? " done" : "")
                        }>
                        <div>
                          <span>Định giá</span>
                        </div>
                      </li>
                      <li
                        className={
                          "step d-flex align-items-center" +
                          (inventionSlt.status === CommonConstant.INVENTION_STATUS_VALUATION ? " active" : "") +
                          (inventionSlt.status >= CommonConstant.INVENTION_STATUS_PROTECTION ? " done" : "")
                        }>
                        <div>
                          <span>Bảo hộ</span>
                        </div>
                      </li>
                      <li
                        className={
                          "step d-flex align-items-center" +
                          (inventionSlt.status === CommonConstant.INVENTION_STATUS_PROTECTION ? " active" : "") +
                          (inventionSlt.status === CommonConstant.INVENTION_STATUS_READY ? " done" : "")
                        }>
                        <div>
                          <span>Sẵn sàng</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <h5 className="text-dark fw-semibold">Thông tin chi tiết</h5>
                <div className="row">
                  <span className="text-dark fw-medium">1. Thông tin cá nhân/tổ chức: </span>
                </div>
                <div className="row">
                  <span className="text-dark">
                    <span className="fw-medium">Tên cá nhân/ tổ chức:</span> {inventionSlt.ownerNm}
                  </span>
                </div>
                <div className="row">
                  <span className="text-dark">
                    <span className="fw-medium">Địa chỉ:</span> {inventionSlt.ownerAddr}
                  </span>
                </div>
                <div className="row">
                  <span className="text-dark">
                    <span className="fw-medium">Số CMND/CCCD/mã số doanh nghiệp:</span> {inventionSlt.ownerIdentify}
                  </span>
                </div>
                <div className="row">
                  <span className="text-dark">
                    <span className="fw-medium">Vai trò đối với công trình nghiên cứu:</span> {inventionSlt.applicantRole?.commNm}
                  </span>
                </div>
                <div className="row">
                  <span className="text-dark">
                    <span className="fw-medium">Số điện thoại:</span> {inventionSlt.ownerPhone}
                  </span>
                </div>
                <div className="row">
                  <span className="text-dark">
                    <span className="fw-medium">Email:</span> {inventionSlt.ownerEmail}
                  </span>
                </div>
                <div className="row">
                  <span className="text-dark fw-medium">2. Thông tin sáng chế: </span>
                </div>
                <div className="row">
                  <span className="text-dark">
                    <span className="fw-medium">Tên sáng chế:</span> {inventionSlt.inventionNm}
                  </span>
                </div>
                <div className="row">
                  <span className="text-dark">
                    <span className="fw-medium">Lĩnh vực nghiên cứu:</span> {inventionSlt.inventionField?.commNm}
                  </span>
                </div>
                <div className="row">
                  <span className="text-dark">
                    <span className="fw-medium">Tóm tắt sáng chế:</span> {inventionSlt.inventionSummary}
                  </span>
                </div>
                {inventionSlt.inventionRecognized && (
                  <div className="row">
                    <span className="text-dark">
                      {inventionSlt.inventionRecognized === true ? (
                        <>
                          <i className="bi bi-check-square me-2 text-success"></i>
                          <span className="fw-medium">Kết quả nghiên cứu khoa học và công nghệ đã đạt được các giải thưởng, công bố, bảo hộ sở hữu trí tuệ trong nước và quốc tế.</span>
                        </>
                      ) : (
                        <>
                          <i className="bi bi-x-square me-2 text-danger"></i>
                          <span className="fw-medium">Kết quả nghiên cứu khoa học và công nghệ chưa được công nhận qua các giải thưởng, công bố, bảo hộ sở hữu trí tuệ trong nước và quốc tế.</span>
                        </>
                      )}
                    </span>
                  </div>
                )}
                <div className="row">
                  <span className="d-flex flex-wrap gap-2 text-dark align-items-center">
                    <span className="fw-medium">Các tài liệu liên quan:&nbsp;</span>
                    {inventionSlt.relatedDocuments?.map((ele: any, idx: any) => (
                      <div key={idx} className="p-2 cursor-pointer bg-gradient-2">
                        <i className="bi bi-file-text me-2"></i>
                        {ele.fleNm}
                      </div>
                    ))}
                  </span>
                </div>
                <div className="row">
                  <span className="text-dark fw-medium">3. Kết quả nghiên cứu: </span>
                </div>
                <div className="row">
                  <span className="d-flex flex-wrap gap-2 text-dark align-items-center">
                    <span className="fw-medium">Bản vẽ, thiết kế, quy trình, sơ đồ:</span>&nbsp;
                    {inventionSlt.technicalsDocuments?.map((ele: any, idx: any) => (
                      <div key={idx} className="p-2 cursor-pointer bg-gradient-2">
                        <i className="bi bi-file-text me-2"></i>
                        {ele.fleNm}
                      </div>
                    ))}
                  </span>
                </div>
                <div className="row">
                  <span className="text-dark">
                    <span className="fw-medium">Kết quả khảo sát, phân tích, đánh giá, thử nghiệm:</span> {inventionSlt.inventionResult}
                  </span>
                </div>
                <div className="row">
                  <span className="text-dark">
                    <span className="fw-medium">Giá trị kinh tế - xã hội, môi trường:</span> {inventionSlt.inventionValue}
                  </span>
                </div>
                <div className="row">
                  <span className="text-dark">
                    <span className="fw-medium">Nội dung, phạm vi, quy mô thực hiện nghiên cứu, ứng dụng chuyển giao:</span> {inventionSlt.transferContent}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </AppModal>
      </div>
    </>
  );
}

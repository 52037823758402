import { Document, Font, Text, Page, StyleSheet, PDFViewer, View } from "@react-pdf/renderer";

export default function InventionRegisPreview({ content }: any) {
  Font.register({
    family: "Noto-Sans-Bold",
    src: require("../../assets/fonts/Noto_Sans/static/NotoSans-Bold.ttf"),
  });
  Font.register({
    family: "Noto-Sans-Regular",
    src: require("../../assets/fonts/Noto_Sans/static/NotoSans-Regular.ttf"),
  });

  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    section: {
      paddingBottom: 20,
    },
    title: {
      fontSize: 14,
      textAlign: "center",
      fontFamily: "Noto-Sans-Bold",
      marginBottom: 5,
    },
    subtitle: {
      fontSize: 13,
      textAlign: "center",
      fontFamily: "Noto-Sans-Bold",
      textDecoration: "underline",
    },
    subject: {
      fontSize: 13,
      textAlign: "left",
      fontFamily: "Noto-Sans-Bold",
    },
    text: {
      margin: 12,
      fontSize: 13,
      textAlign: "justify",
      fontFamily: "Noto-Sans-Regular",
    },
    textBold: {
      margin: 12,
      fontSize: 13,
      textAlign: "justify",
      fontFamily: "Noto-Sans-Bold",
    },
    sectionNote: {
      borderTop: "1px solid black",
      marginTop: 20,
    },
    textNote: { fontSize: 10, textAlign: "justify", fontFamily: "Noto-Sans-Regular" },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  return (
    <PDFViewer className="preview-pdf">
      <Document>
        <Page size={"A4"} style={styles.body}>
          <View style={styles.section}>
            <Text style={styles.title}>CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</Text>
            <Text style={styles.subtitle}>Độc lập - Tự do - Hạnh phúc</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.title}>ĐƠN ĐỀ NGHỊ</Text>
            <Text style={styles.title}>CÔNG NHẬN KẾT QUẢ NGHIÊN CỨU KHOA HỌC VÀ PHÁT TRIỂN</Text>
            <Text style={styles.title}>CÔNG NGHỆ CỦA TỔ CHỨC, CÁ NHÂN</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.subject}>1. Thông tin cá nhân/ tổ chức:</Text>
            <Text style={styles.text}>- Tên tổ chức, cá nhân đề nghị: {content.ownerNm}</Text>
            <Text style={styles.text}>- Số CMND/CCCD hoặc mã số DN (nếu có): {content.ownerIdentify}</Text>
            <Text style={styles.text}>- Địa chỉ: {content.ownerAddr}</Text>
            <Text style={styles.text}>
              - Số điện thoại: {content.ownerPhone}&emsp;- Email: {content.ownerEmail}
            </Text>
            <Text style={styles.text}>- Vai trò đối với công trình nghiên cứu: {content.applicantRole?.label}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.subject}>2. Thông tin sáng chế:</Text>
            <Text style={styles.text}>- Tên sáng chế: {content.inventionNm}</Text>
            <Text style={styles.text}>- Lĩnh vực nghiên cứu: {content.researchField?.label}</Text>
            <Text style={styles.text}>- Tóm tắt sáng chế: {content.inventionSummary}</Text>
            {content.inventionRecognized && (
              <Text style={styles.textBold}>
                {content.inventionRecognized === "true" && "Kết quả nghiên cứu khoa học và công nghệ đã đạt được các giải thưởng, công bố, bảo hộ sở hữu trí tuệ trong nước và quốc tế."}
                {content.inventionRecognized === "false" && "Kết quả nghiên cứu khoa học và công nghệ chưa được công nhận qua các giải thưởng, công bố, bảo hộ sở hữu trí tuệ trong nước và quốc tế."}
              </Text>
            )}
            <Text style={styles.text}>
              - Các tài liệu liên quan (nếu có):&nbsp;
              {content.attachFiles?.map((file: any, idx: any) => (
                <div key={idx}>{idx !== content.attachFiles.length - 1 ? <>{file.name},&nbsp;</> : <>{file.name}</>}</div>
              ))}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.subject}>3. Kết quả nghiên cứu:</Text>
            <Text style={styles.text}>
              - Bản vẽ, thiết kế, quy trình, sơ đồ:&nbsp;
              {content.technicalFiles?.map((file: any, idx: any) => (
                <div key={idx}>{idx !== content.technicalFiles.length - 1 ? <>{file.name},&nbsp;</> : <>{file.name}</>}</div>
              ))}
            </Text>
            <Text style={styles.text}>- Kết quả khảo sát, phân tích, đánh giá, thử nghiệm: {content.inventionResult}</Text>
            <Text style={styles.text}>- Giá trị kinh tế - xã hội, môi trường: {content.inventionValue}</Text>
            <Text style={styles.text}>- Nội dung, phạm vi, quy mô thực hiện nghiên cứu, ứng dụng chuyển giao: {content.transferContent}</Text>
          </View>
          <View style={styles.sectionNote}>
            <Text style={styles.subject}>Ghi chú:</Text>
            <Text style={styles.textNote}>(1): Cơ quan có thẩm quyền công nhận quy định tại khoản 2 Điều 18 Nghị định số 76/2018/NĐ-CP ngày 15 tháng 5 năm 2018</Text>
            <Text style={styles.textNote}>
              (2): Nếu người đề nghị là cá nhân thì ghi rõ họ tên; Trường hợp là tổ chức thì ghi tên tổ chức và ghi họ tên của người đại diện theo pháp luật của tổ chức đó. Nếu người đề nghị là cá
              nhân thì ghi mã định danh cá nhân, nếu là cơ quan, tổ chức thì ghi địa chỉ trụ sở chính của cơ quan, tổ chức tại thời điểm nộp văn bản đề nghị.
            </Text>
            <Text style={styles.textNote}>(3): Ghi tên kết quả nghiên cứu khoa học và phát triển công nghệ. Làm rõ tính mới, ưu việt, tiên tiến, đại bàn áp dụng,…</Text>
            <Text style={styles.textNote}>(4): Các tài liệu (nếu có) theo quy định tại điểm đ khoản 1 Điều 18 Nghị định số 76/2018/NĐ-CP ngày 15 tháng 5 năm 2018.</Text>
            <Text style={styles.textNote}>(5): Ký, ghi rõ họ tên, đóng dấu nếu là cơ quan, tổ chức.</Text>
          </View>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
        </Page>
      </Document>
    </PDFViewer>
  );
}

import React, { useEffect, useState } from "react";

type AppModalProps = {
  title?: React.ReactNode | undefined;
  /**
   * Custom header
   * @defaultValue undefined
   */
  header?: React.ReactNode | undefined;

  /**
   * Custom footer
   * @defaultValue undefined
   */
  footer?: React.ReactNode | undefined;

  isShow: boolean;
  /**
   * size of modal.
   * @defaultValue true
   */
  // size?: 'sm' | 'md' | 'lg' | undefined
  /**
   * Show and hide headers.
   * @defaultValue true
   */
  showHeader?: "true" | "false" | undefined;

  /**
   * Show and hide footer.
   * @defaultValue true
   */
  showFooter?: "true" | "false" | undefined;

  /**
   * Used to get the child elements of the component.
   * @readonly
   */
  children?: React.ReactNode | undefined;

  onClose?: (event?: any) => void;
};

export default function AppModal(props: AppModalProps) {
  const [isShow, setIsShow] = useState<boolean>(false);

  useEffect(() => {
    setIsShow(props.isShow);
  }, [props.isShow]);

  const onClose = (event?: any) => {
    props.onClose?.(event);
    setIsShow(false);
  };

  return (
    <>
      {isShow && (
        <>
          <div className="modal-backdrop"></div>
          <div className="modal fade show" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-scrollable modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5 fw-semibold">{props.title}</h1>
                  <button type="button" className="btn-close" onClick={() => onClose()}></button>
                </div>
                <div className="modal-body">{props.children}</div>
                {props.showFooter != "false" && !props.footer && (
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary">
                      Close
                    </button>
                    <button type="button" className="btn btn-primary">
                      Save changes
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

import axios from "axios";
import { ParamUtil, RequestParam } from "../utils/paramUtil";
import { ApiUrlUtil } from "../utils/apiUrlUtil";
import { HeadersUtil } from "../utils/headersUtil";

export default class InventionService {
  private static service: InventionService;

  public static getInstance(): InventionService {
    if (!InventionService.service) {
      InventionService.service = new InventionService();
    }
    return InventionService.service;
  }

  public searchInventionApproved(searchReq: any) {
    const params: RequestParam[] = ParamUtil.toRequestParams(searchReq);
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_INFO_MNG_URL + "/invention/searchInvention", params);
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getAllInvention(searchReq: any) {
    const params: RequestParam[] = ParamUtil.toRequestParams(searchReq);
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_INFO_MNG_URL + "/invention/getAllInvention", params);
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public getInventionByCreatedBy(searchReq: any) {
    const params: RequestParam[] = ParamUtil.toRequestParams(searchReq);
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_INFO_MNG_URL + "/invention/getInventionByCreatedBy", params);
    return axios.get(url, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }

  public saveInvention(invention: any) {
    const url = process.env.REACT_APP_API_INFO_MNG_URL + "/invention/save";
    return axios.post(url, invention, {
      headers: HeadersUtil.getHeadersAuth(),
    });
  }
}

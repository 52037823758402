import { useEffect, useState } from "react";
import { CommonConstant } from "../core/constant/common.constant";

export default function ViewApproveStatus({ value }: any) {
  const [inventionStt, setInventionStt] = useState<any>({});

  useEffect(() => {
    const temp = CommonConstant.LIST_APPROVE_STATUS.find((ele) => ele.value == value);
    if (temp) {
      setInventionStt(temp);
    }
  }, [value]);

  return <>{inventionStt.label}</>;
}

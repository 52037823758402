'use client';

import { useEffect, useState } from 'react';
import Select from 'react-select';

type AppSelectProps = {
    /**
     * Value to display.
     *
     */
    defaultValue?: any | undefined;

    labelName: string;

    labelValue: string;

    placeHolder?: string;

    options: any[];

    isMulti?: 'true' | 'false' | undefined;

    /**
     *
     * @defaultValue false
     */
    disabled?: 'true' | 'false' | undefined;
    /**
     *
     * @defaultValue false
     */
    clearable?: 'true' | 'false' | undefined;

    onChange?: (event: any) => void;

    value?: any;
};

export default function AppSelect(props: AppSelectProps) {
    const [options, setOptions] = useState<any[]>([]);

    useEffect(() => {
        const temps = props.options?.map((ele) => {
            return { label: ele[props.labelName], value: ele[props.labelValue] };
        });
        setOptions(temps);
    }, [props.options]);

    const customStyles = {
        option: (provided: any, state: any) => {
            return {
                ...provided,
                background: state.data === state.selectProps.value ? '#000000 !important' : '#fff',
                color: state.data === state.selectProps.value ? '#fff !important' : '#000000',
                '&:hover': {
                    color: '#000000',
                    fontWeight: 'bold',
                    background: '#fff',
                    cursor: 'pointer'
                }
            };
        }
    };

    return <>
        <Select
            className='custom-select'
            placeholder={props.placeHolder}
            defaultValue={props.defaultValue} 
            options={options}
            styles={customStyles}
            onChange={props.onChange} 
            isClearable={props.clearable == 'true' ? true : false} 
            isDisabled={props.disabled == 'true' ? true : false} 
            instanceId="select"
            value={props.value}
        />
    </>
    
}

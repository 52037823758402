export default function Footer() {
  return (
    <>
      <div className="footer bg-dark">
        {/* <div className="section-spacing-sm">
          <div className="container">
            <div className="row g-4 g-lg-5">
              <div className="col-12 col-lg-4">
                <h2 className="uppercase letter-spacing-1">Nuur</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quo sit facilis cum nisi magnam</p>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <h6 className="sm-heading">Email:</h6>
                <h3>contact@example.com</h3>
                <p>Mon-Fri 9am-5pm</p>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <h6 className="sm-heading">Phone:</h6>
                <h3>+123 456 7890</h3>
                <p>Call or WhatsApp</p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container">
          {/* <hr className="hr-diagonal" /> */}
          <div className="py-4">
            <div className="row g-2">
              <div className="col-12 col-md-9 text-center text-md-start">
                <div
                    className="d-flex flex-column flex-md-row gap-2 gap-md-5 justify-content-center justify-content-md-between">
                  <div>Hệ Thống Quản Lý Dữ Liệu KHCN Quốc Gia</div>
                  <div>Email: contact@example.com</div>
                  <div>Phone: +123 456 7890</div>
                </div>
              </div>
              <div className="col-12 col-md-3 text-center text-md-end d-flex justify-content-md-end justify-content-center">
                <ul className="list-inline-dot">
                  <li>
                    <a className="link-hover-line" href="#">
                      About us
                    </a>
                  </li>
                  <li>
                    <a className="link-hover-line" href="#">
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a className="link-hover-line" href="#">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

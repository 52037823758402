export class CommonConstant {
  public static readonly INVENTION_STATUS_REGISTERED = "02-01";
  public static readonly INVENTION_STATUS_VALUATION = "02-02";
  public static readonly INVENTION_STATUS_PROTECTION = "02-03";
  public static readonly INVENTION_STATUS_READY = "02-04";

  public static readonly LIST_INVENTION_STATUS = [
    { label: "Đã đăng ký", value: "02-01" },
    { label: "Đã định giá", value: "02-02" },
    { label: "Đã bảo hộ", value: "02-03" },
    { label: "Sẵn sàng", value: "02-04" },
  ];

  public static readonly LIST_APPROVE_STATUS = [
    { label: "Đã duyệt", value: "05-01" },
    { label: "Đang chờ", value: "05-02" },
    { label: "Từ chối", value: "05-03" },
  ];

  public static readonly RESEARCH_FIELD = [
    { label: "Công nghệ thông tin và viễn thông", value: "03-01" },
    { label: "Công nghệ năng lượng", value: "03-02" },
    { label: "Công nghệ sinh học và y tế", value: "03-03" },
    { label: "Điện tử và mạch điện", value: "03-04" },
    { label: "Công nghệ vật liệu", value: "03-05" },
    { label: "Công nghệ ô tô và giao thông", value: "03-06" },
    { label: "Công nghệ sản xuất và chế tạo", value: "03-07" },
    { label: "Công nghệ môi trường", value: "03-08" },
    { label: "Công nghệ thực phẩm và nông nghiệp", value: "03-09" },
  ];

  public static readonly APPLICANT_ROLES = [
    { label: "Tác giả của công trình", value: "04-01" },
    { label: "Đồng tác giả trong nhóm nghiên cứu", value: "04-02" },
    { label: "Tổ chức dịch vụ đại diện được uỷ quyền", value: "04-03" },
  ];

  public static readonly INVENTION_APPROVE_STATUS_APPROVED = "05-01";
  public static readonly INVENTION_APPROVE_STATUS_PENDING = "05-02";
  public static readonly INVENTION_APPROVE_STATUS_REJECTED = "05-03";
}

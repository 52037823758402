import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect } from "react";
import { getUserInfo } from "../libs/reducers/userSlice";
import { useAppDispatch } from "../libs/hooks";

export default function Layout() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  return (
    <div className="position-relative" style={{ minHeight: "100vh" }}>
      <Header />
      <div className="container-full-bg" style={{ paddingBottom: "80px" }}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

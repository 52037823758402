import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/svg/logo2.svg";
import Cookies from "universal-cookie";
import { useAppDispatch, useAppSelector } from "../libs/hooks";
import { AuthConstant } from "../constants/authConstant";
import { useEffect } from "react";
import { getUserInfo } from "../libs/reducers/userSlice";

export default function Header() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const _token = params.get("access_token");
  const location = useLocation();
  const cookie = new Cookies();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const isLogin = useAppSelector((state) => state.userInfo.isLogin);
  const navigate = useNavigate();

  if (_token) {
    const expires = new Date();
    expires.setDate(expires.getDate() + AuthConstant.EXPIRES_TOKEN);
    cookie.remove(AuthConstant.ACCESS_TOKEN);
    cookie.remove(AuthConstant.PUBLIC_KEY);
    cookie.set(AuthConstant.ACCESS_TOKEN, _token, {
      path: "/",
      expires: expires,
    });
    window.location.href = "/";
  }

  const login = () => {
    let domain = window.location.origin;
    var url = process.env.REACT_APP_AUTH_URL + "/login?redirect_uri=" + domain || "";
    window.location.href = url;
  };

  const logout = () => {
    const cookies = new Cookies();
    cookies.remove(AuthConstant.ACCESS_TOKEN);
    // navigate("/");
    window.location.reload();
  };

  return (
    <div className={"header" + (location.pathname.includes("trang-chu") ? " transparent-dark" : "")}>
      <div className="container-fluid px-xxl-5">
        <div className="header-logo">
          <a href="/">
            <img src={logo} style={{ height: "50px" }} alt="" />
          </a>
        </div>
        <div className="header-menu nav-link-hover-line">
          <ul className="nav">
            <li className="nav-item">
              <Link className="nav-link" to="/trang-chu">
                Trang chủ
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/ve-chung-toi">
                Về NSTD
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="javascript:void(0)">
                Dịch vụ
              </a>
              <ul className="nav-dropdown">
                <li className="nav-dropdown-item">
                  <a className="nav-dropdown-link" href="https://dinhgia.dlkhcn.io.vn">
                    Định giá
                  </a>
                </li>
                <li className="nav-dropdown-item">
                  <a className="nav-dropdown-link" href="https://baoho.dlkhcn.io.vn">
                    Bảo hộ
                  </a>
                </li>
                <li className="nav-dropdown-item">
                  <a className="nav-dropdown-link" href="https://chuyengiao.dlkhcn.io.vn">
                    Chuyển nhượng
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/lien-he">
                Liên hệ
              </Link>
            </li>
            {!isLogin ? (
              <li className="nav-item">
                <a onClick={login} className="button button-sm button-rounded button-gradient-5 cursor-pointer button-hover-slide ms-3 ms-lg-4">
                  Đăng nhập
                </a>
              </li>
            ) : (
              <li className="nav-item">
                <i className="bi bi-person-circle fs-4 me-2"></i>
                <a className="nav-link" href="javascript:void(0)">
                  {userInfo.fullName}
                </a>
                <ul className="nav-dropdown">
                  <li className="nav-dropdown-item">
                    <Link className="nav-dropdown-link" to="">
                      Thông tin cá nhân
                    </Link>
                  </li>
                  <li className="nav-dropdown-item">
                    <Link className="nav-dropdown-link" to="/sang-che-cua-toi">
                      Sáng chế của tôi
                    </Link>
                  </li>
                  <li className="nav-dropdown-item">
                    <a className="nav-dropdown-link" onClick={logout}>
                      Đăng xuất
                    </a>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
        <button className="mobile-menu-toggle">
          <span></span>
        </button>
      </div>
    </div>
  );
}

import Layout from "../layouts/Layout";
import About from "../pages/about/About";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import Contact from "../pages/contact/Contact";
import HomePage from "../pages/homepage/HomePage";
import InventionMng from "../pages/invention-mng/InventionMng";
import PatentRegistration from "../pages/patent-registration/PatentRegistration";
import Search from "../pages/search/Search";
import Service from "../pages/service/Service";

export const indexRouter: any = {
  path: "/",
  element: <Layout />,
  children: [
    { path: "trang-chu", element: <HomePage /> },
    { path: "dich-vu", element: <Service /> },
    { path: "ve-chung-toi", element: <About /> },
    { path: "lien-he", element: <Contact /> },
    { path: "dang-nhap", element: <Login /> },
    { path: "dang-ky", element: <Register /> },
    { path: "dang-ky-sang-che", element: <PatentRegistration /> },
    { path: "sang-che-cua-toi", element: <InventionMng /> },
    { path: "tim-kiem", element: <Search /> },
  ],
};
